<template>
  <div>
    <!-- BOOKING INFO -->
    <b-row>
      <b-col cols="6" sm="3" class="mb-2 mb-sm-0">
        <h6 class="mb-25">Nº Reserva</h6>
        <b-booking-localizator :localizator="bookingLocalizator" />
      </b-col>
      <b-col cols="6" sm="3" class="mb-2 mb-sm-0">
        <h6 class="mb-25">Allotjament</h6>
        <b-card-text>{{ accommodationName }}</b-card-text>
      </b-col>
      <b-col cols="6" sm="3">
        <h6 class="mb-25">Entrada</h6>
        <b-card-text>{{ bookingCheckin }}</b-card-text>
      </b-col>
      <b-col cols="6" sm="3">
        <h6 class="mb-25">Sortida</h6>
        <b-card-text>{{ bookingCheckout }}</b-card-text>
      </b-col>
    </b-row>

    <!-- BILLING SUMMARY -->
    <b-row class="mt-2">
      <!-- CLIENT BILLING SUMMARY -->
      <b-col cols="12" sm="3">
        <div class="bg-light p-2 border-warning">
          <div class="font-weight-bold mb-2">Facturació al client</div>
          <div class="d-flex justify-content-between">
            <div>Total:</div>
            <div class="mr-50">
              {{ formatCurrency(clientBillingTotal) }}
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <div>Facturat:</div>
            <div class="mr-50">
              {{ formatCurrency(clientBillingInvoiced) }}
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <div>Pendent:</div>
            <div class="mr-50">
              {{ formatCurrency(clientBillingPending) }}
            </div>
          </div>
        </div>
      </b-col>

      <!-- OWNER BILLING SUMMARY -->
      <b-col cols="12" sm="3">
        <div class="bg-light p-2 border-success">
          <div class="mb-2 font-weight-bold">Facturació al propietari</div>
          <div class="d-flex justify-content-between">
            <div>Total:</div>
            <div class="mr-50">
              {{ formatCurrency(ownerBillingTotal) }}
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <div>Facturat:</div>
            <div class="mr-50">
              {{ formatCurrency(ownerBillingInvoiced) }}
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <div>Pendent:</div>
            <div class="mr-50">
              {{ formatCurrency(ownerBillingPending) }}
            </div>
          </div>
        </div>
      </b-col>

      <!-- OWNER LIQUIDATION SUMMARY -->
      <b-col cols="12" sm="6">
        <div class="bg-light p-2 border-danger">
          <div class="mb-2 font-weight-bold">Liquidació al propietari</div>
          <div class="d-flex justify-content-between">
            <div>Total:</div>
            <div class="mr-50">200,00 €</div>
          </div>
          <div class="d-flex justify-content-between">
            <div>Liquidat:</div>
            <div class="mr-50">0,00 €</div>
          </div>
          <div class="d-flex justify-content-between">
            <div>Pendent:</div>
            <div class="mr-50">200,00 €</div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import { formatCurrency, formatDateStringToDate } from "@/utils/formatters";
import BBookingLocalizator from "@foravila-core/components/b-booking-localizator/BBookingLocalizator.vue";

export default {
  components: {
    BRow,
    BCol,
    BBookingLocalizator,
  },
  data() {
    return {
      formatCurrency,
    };
  },
  computed: {
    booking() {
      return this.$store.getters["booking/booking"];
    },
    bookingLocalizator() {
      return this.booking?.localizator || null;
    },
    bookingCheckin() {
      if (!this.booking?.checkin) {
        return null;
      }

      return formatDateStringToDate(this.booking.checkin);
    },
    bookingCheckout() {
      if (!this.booking?.checkout) {
        return null;
      }

      return formatDateStringToDate(this.booking.checkout);
    },
    accommodation() {
      return this.booking?.accommodation || null;
    },
    accommodationName() {
      return this.accommodation?.name || null;
    },
    contract() {
      return this.$store.getters["booking/contract"];
    },
    billingType() {
      return this.contract?.billingType || null;
    },
    stayPvp() {
      return this.$store.getters["booking/subtotalDiscounted"];
    },
    stayComission() {
      return this.$store.getters["booking/agencySubtotal"];
    },
    stayComissionInvoiced() {
      return this.$store.getters["booking/stayComissionInvoicedPrice"];
    },
    stayComissionPending() {
      return this.$store.getters["booking/stayComissionInvoicePendingPrice"];
    },
    clientBillingTotal() {
      let total = 0;

      // TODO

      if (this.billingType === "SUBURANCE") {
        total += this.stayPvp || 0;
      }

      return total;
    },
    clientBillingInvoiced() {
      let total = 0;

      // TODO

      if (this.billingType === "SUBURANCE") {
        total += this.stayPvp || 0;
      }

      return total;
    },
    clientBillingPending() {
      return this.clientBillingTotal - this.clientBillingInvoiced;
    },
    ownerBillingTotal() {
      let total = 0;

      // TODO

      if (this.billingType === "COMISSION") {
        total += this.stayComission || 0;
      }

      return total;
    },
    ownerBillingInvoiced() {
      let total = 0;

      // TODO

      if (this.billingType === "COMISSION") {
        total += this.stayComission || 0;
      }

      return total;
    },
    ownerBillingPending() {
      return this.ownerBillingTotal - this.ownerBillingInvoiced;
    },
  },
};
</script>
