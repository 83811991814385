<template>
  <div
    class="w-100 pt-2 pb-50 px-1 bg-light border d-flex flex-column align-items-center cursor-pointer"
    @click="previewInvoice"
  >
    <!-- ICON -->
    <b-img
      class="cursor-pointer"
      :src="require('@/assets/foravila/images/icons/icon-pdf.svg')"
      height="40"
    />

    <!-- LOCALIZATOR -->
    <small v-if="invoiceLocalizator" class="mt-1 font-weight-bolder">
      {{ invoiceLocalizator }}
    </small>

    <!-- AMOUNT -->
    <small v-if="invoicePvp">
      {{ invoicePvp }}
    </small>

    <!-- BUTTONS -->
    <div class="mt-50">
      <b-button
        class="btn-icon"
        variant="flat-secondary"
        @click.stop="sendInvoice"
      >
        <feather-icon icon="SendIcon" />
      </b-button>
      <b-button
        class="btn-icon"
        variant="flat-secondary"
        @click.stop="downloadInvoice"
      >
        <feather-icon icon="DownloadIcon" />
      </b-button>
    </div>
  </div>
</template>

<script>
import { BImg, BButton } from "bootstrap-vue";
import { formatCurrency } from "@/utils/formatters";

export default {
  components: {
    BImg,
    BButton,
  },
  props: {
    invoice: {
      type: Object,
      required: true,
    },
  },
  computed: {
    invoiceLocalizator() {
      return this.invoice?.localizator || null;
    },
    invoicePvp() {
      if (this.invoice?.pvpPrice == null) {
        return null;
      }
      return formatCurrency(this.invoice.pvpPrice);
    },
  },
  methods: {
    previewInvoice() {
      this.$emit("preview", this.invoice);
    },
    sendInvoice() {
      this.$emit("send", this.invoice);
    },
    downloadInvoice() {
      this.$emit("download", this.invoice);
    },
  },
};
</script>
