<template>
  <b-overlay :show="loadingOwner" variant="white" opacity="0.9" size="10">
    <b-form>
      <b-row>
        <b-col cols="12">
          <label for="email">Afegeix correus separats per una coma</label>

          <b-form-tags
            v-model="recipientEmails"
            input-id="email"
            separator=" ,;"
            placeholder="Afegeix un correu"
            no-add-on-enter
            class="mb-2"
          />
        </b-col>
        <b-col cols="12">
          <b-form-group label="Assumpte" label-for="assumpte">
            <b-form-input
              id="assumpte"
              v-model="subject"
              type="text"
              placeholder="rowSelected"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <label for="missatge">Missatge</label>
          <b-form-textarea id="missatge" placeholder="Missatge" rows="3" />
        </b-col>
      </b-row>
    </b-form>
  </b-overlay>
</template>

<script>
import {
  BFormGroup,
  BForm,
  BFormInput,
  BRow,
  BCol,
  BFormTextarea,
  BFormTags,
  BOverlay,
} from "bootstrap-vue";
export default {
  components: {
    BFormGroup,
    BForm,
    BFormInput,
    BRow,
    BCol,
    BFormTextarea,
    BFormTags,
    BOverlay,
  },
  props: {
    invoice: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      subject: "",
      recipientEmails: [],
    };
  },
  computed: {
    loadingOwner() {
      return this.$store.getters["booking/loadingOwner"];
    },
    owner() {
      return this.$store.getters["booking/owner"];
    },
  },
  watch: {
    owner(owner) {
      this.initOwnerEmail(owner);
    },
  },
  created() {
    this.initOwnerEmail(this.owner);
    this.subject = "Factura " + this.invoice?.localizator;
  },
  methods: {
    initOwnerEmail(owner) {
      if (!owner?.email) return;
      this.recipientEmails.push(owner.email);
    },
  },
};
</script>
