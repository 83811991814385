<template>
  <div id="pending-billing-table">
    <vue-good-table
      :columns="columns"
      :rows="rows"
      style-class="vgt-table "
      :select-options="selectOptions"
      :sort-options="sortOptions"
      @on-selected-rows-change="onSelectedRowsChange"
    >
      <template slot="table-row" slot-scope="props">
        <template v-if="props.column.field === 'baseAmount'">
          {{ formatCurrency(props.row.baseAmount) }}
        </template>
        <template v-else-if="props.column.field === 'vatAmount'">
          {{ formatCurrency(props.row.vatAmount) }}
        </template>
        <template v-else-if="props.column.field === 'irpfAmount'">
          {{ formatCurrency(props.row.irpfAmount) }}
        </template>
        <template v-else-if="props.column.field === 'totalAmount'">
          {{ formatCurrency(props.row.totalAmount) }}
        </template>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import { VueGoodTable } from "vue-good-table";
import { formatCurrency } from "@/utils/formatters";

export default {
  components: {
    VueGoodTable,
  },
  props: {
    rows: {
      type: Array,
      default: () => [],
    },
    invoiceType: {
      type: String,
    },
    hideVat: {
      type: Boolean,
      default: false,
    },
    hideIrpf: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedRows: [],
      sortOptions: {
        enabled: false,
      },
      selectOptions: {
        enabled: true,
        selectOnCheckboxOnly: true,
        disableSelectInfo: true,
      },
      formatCurrency,
    };
  },
  computed: {
    booking() {
      return this.$store.getters["booking/booking"];
    },
    recipient() {
      return this.invoiceType.split("_TO_")[1];
    },
    tableHeaderName() {
      let tableHeaderName = "";
      if (this.invoiceType === "AGENCY_TO_OWNER") {
        return (tableHeaderName = "A facturar al propietari");
      }
      if (this.invoiceType === "AGENCY_TO_CLIENT") {
        return (tableHeaderName = "A facturar al client");
      }
      if (this.invoiceType === "OWNER_SETTLEMENT") {
        return (tableHeaderName = "A liquidar al propietari");
      }
      return tableHeaderName;
    },
    columns() {
      const cols = [];
      cols.push({ label: this.tableHeaderName, field: "description" });
      cols.push({ label: "Base", field: "baseAmount", width: "100px" });
      if (!this.hideVat) {
        cols.push({ label: "I.V.A.", field: "vatAmount", width: "100px" });
      }
      if (!this.hideIrpf) {
        cols.push({ label: "IRPF", field: "irpfAmount", width: "100px" });
      }
      cols.push({ label: "Total", field: "totalAmount", width: "100px" });

      return cols;
    },
  },
  watch: {
    selectedRows(selectedRows) {
      this.$emit("rows-selected", selectedRows);
    },
  },
  methods: {
    onSelectedRowsChange(params) {
      this.selectedRows = params.selectedRows;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
#pending-billing-table {
  .vgt-table {
    font-size: 1rem;
  }
  thead th {
    background: $light;
  }
}
</style>
