<template>
  <div
    class="w-100 pt-2 pb-50 px-1 bg-light border d-flex flex-column align-items-center cursor-pointer"
    @click="previewLiquidation"
  >
    <!-- ICON -->
    <b-img
      class="cursor-pointer"
      :src="require('@/assets/foravila/images/icons/icon-pdf.svg')"
      height="40"
    />

    <!-- LOCALIZATOR -->
    <small class="mt-1 font-weight-bolder">
      {{ $t("Liquidació") }}
    </small>

    <!-- AMOUNT -->
    <small v-if="liquidationPvp">
      {{ liquidationPvp }}
    </small>

    <div class="mt-50">
      <!-- SEND BUTTON -->
      <b-button
        class="btn-icon"
        variant="flat-secondary"
        @click.stop="sendLiquidation"
      >
        <feather-icon icon="SendIcon" />
      </b-button>

      <!-- DOWNLOAD BUTTON -->
      <b-button
        class="btn-icon"
        variant="flat-secondary"
        @click.stop="downloadLiquidation"
      >
        <feather-icon icon="DownloadIcon" />
      </b-button>
    </div>
  </div>
</template>

<script>
import { BImg, BButton } from "bootstrap-vue";
import { formatCurrency } from "@/utils/formatters";

export default {
  components: {
    BImg,
    BButton,
  },
  props: {
    liquidation: {
      type: Object,
      required: true,
    },
  },
  computed: {
    liquidationLocalizator() {
      return this.liquidation?.localizator || null;
    },
    liquidationPvp() {
      if (this.liquidation?.pvpPrice == null) {
        return null;
      }
      return formatCurrency(this.liquidation.pvpPrice);
    },
  },
  methods: {
    previewLiquidation() {
      this.$emit("preview", this.liquidation);
    },
    sendLiquidation() {
      this.$emit("send", this.liquidation);
    },
    downloadLiquidation() {
      this.$emit("download", this.liquidation);
    },
  },
};
</script>
