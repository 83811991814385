<template>
  <div>
    <pending-billing-table
      v-if="billingRows.length"
      :rows="billingRows"
      invoice-type="AGENCY_TO_CLIENT"
      @rows-selected="onRowsSelected"
    />
  </div>
</template>

<script>
import { AGENCY_TO_CLIENT_INVOICE_VAT_PERCENTAGE } from "@appConfig";
import PendingBillingTable from "@/views/accounting/billing/details/components/PendingBillingTable.vue";
import {
  getBookingServicePvpInvoicePendingPrice,
  getBookingServicePvpIsInvoiced,
  getBookingServicePvpIsOverInvoiced,
  getBookingServicePvpIsUnderInvoiced,
  calculateBasePriceFromPvpPrice,
  calculatePercentagePriceFromBasePrice,
  getServiceName,
  calculatePvpPrice,
  getBookingServiceComissionIsInvoiced,
  getBookingServiceComissionIsUnderInvoiced,
  getBookingServiceComissionIsOverInvoiced,
  getBookingServiceComissionPrice,
  getBookingServiceComissionInvoicePendingPrice,
} from "@/utils/methods";

export default {
  components: {
    PendingBillingTable,
  },
  data() {
    return {
      billingRows: [],
    };
  },
  created() {
    this.initBillingRows();
  },
  computed: {
    contract() {
      return this.$store.getters["booking/contract"];
    },
    billingType() {
      return this.contract?.billingType || null;
    },
    booking() {
      return this.$store.getters["booking/booking"];
    },
    bookingSubtotal() {
      return this.$store.getters["booking/subtotalDiscounted"];
    },
    services() {
      return this.$store.getters["booking/services"];
    },
    isStayPvpInvoiced() {
      return this.$store.getters["booking/isStayPvpInvoiced"];
    },
    isStayPvpUnderInvoiced() {
      return this.$store.getters["booking/isStayPvpUnderInvoiced"];
    },
    isStayPvpOverInvoiced() {
      return this.$store.getters["booking/isStayPvpOverInvoiced"];
    },
    stayPvpInvoicePendingPrice() {
      return this.$store.getters["booking/stayPvpInvoicePendingPrice"];
    },
  },
  watch: {
    isStayPvpInvoiced() {
      this.initBillingRows();
    },
    services() {
      this.initBillingRows();
    },
  },
  methods: {
    initBillingRows() {
      this.billingRows = [];

      // STAY PVP INVOICE LINE
      if (this.billingType === "SUBURANCE") {
        let description;
        let totalAmount;

        if (!this.isStayPvpInvoiced) {
          description = `Estada · Reserva ${this.booking.localizator}`;
          totalAmount = this.bookingSubtotal;
        } else if (this.isStayPvpUnderInvoiced || this.isStayPvpOverInvoiced) {
          description = this.isStayPvpUnderInvoiced
            ? `Estada restant · Reserva ${this.booking.localizator}`
            : `Compensació estada · Reserva ${this.booking.localizator}`;
          totalAmount = this.stayPvpInvoicePendingPrice;
        }

        // Only add the row if the stay pvp is not exactly invoiced
        if (description) {
          const baseAmount = calculateBasePriceFromPvpPrice(
            totalAmount,
            AGENCY_TO_CLIENT_INVOICE_VAT_PERCENTAGE
          );
          const vatAmount = calculatePercentagePriceFromBasePrice(
            baseAmount,
            AGENCY_TO_CLIENT_INVOICE_VAT_PERCENTAGE
          );
          const irpfAmount = 0;

          this.billingRows.push({
            description,
            baseAmount,
            vatAmount,
            irpfAmount,
            totalAmount,
            invoiceLineType: "STAY_PVP",
          });
        }
      }

      // EARLY RETURN IN CASE THERE ARE NO BOOKING SERVICES
      if (!this.services.length) {
        return this.billingRows;
      }

      // SERVICES INVOICE LINES
      this.services.forEach((bookingService) => {
        const {
          invoiceHolder,
          service,
          costPrice,
          pvpPrice,
          vatPercentage,
          irpfPercentage,
        } = bookingService;

        const ownerPriceVatAmount = calculatePercentagePriceFromBasePrice(
          costPrice,
          vatPercentage || 0
        );
        const ownerPriceIrpfAmount = calculatePercentagePriceFromBasePrice(
          costPrice,
          irpfPercentage || 0
        );
        const ownerPriceWithVat = calculatePvpPrice(
          costPrice,
          ownerPriceVatAmount,
          ownerPriceIrpfAmount
        );

        if (service?.code !== "SECURITY_DEPOSIT") {
          // SERVICE INVOICE: if the invoice holder is the agency, add the invoice for the
          // whole service
          if (invoiceHolder === "AGENCY") {
            let description;
            let totalAmount;

            if (!getBookingServicePvpIsInvoiced(bookingService)) {
              description = this.getServiceNameText(bookingService);
              totalAmount =
                bookingService.pvpPrice != null
                  ? parseInt(bookingService.pvpPrice)
                  : null;
            } else if (
              getBookingServicePvpIsUnderInvoiced(bookingService) ||
              getBookingServicePvpIsOverInvoiced(bookingService)
            ) {
              description = getBookingServicePvpIsUnderInvoiced(bookingService)
                ? `Servei restant · ${this.getServiceNameText(bookingService)}`
                : `Compensació servei · ${this.getServiceNameText(
                    bookingService
                  )}`;
              totalAmount =
                getBookingServicePvpInvoicePendingPrice(bookingService);
            }

            if (description) {
              const baseAmount = calculateBasePriceFromPvpPrice(
                totalAmount,
                AGENCY_TO_CLIENT_INVOICE_VAT_PERCENTAGE
              );
              const vatAmount = calculatePercentagePriceFromBasePrice(
                baseAmount,
                AGENCY_TO_CLIENT_INVOICE_VAT_PERCENTAGE
              );
              const irpfAmount = 0;

              this.billingRows.push({
                description,
                baseAmount,
                vatAmount,
                irpfAmount,
                totalAmount,
                invoiceLineType: "SERVICE_PVP",
                service: bookingService["@id"],
              });
            }
          }

          // SERVICE COMISSION INVOICE: if the invoice holder is not the agency, add the invoice
          // only for the comission of the service (pvp - cost)
          else if (ownerPriceWithVat > 0 && ownerPriceWithVat < pvpPrice) {
            let description;
            let totalAmount;

            if (!getBookingServiceComissionIsInvoiced(bookingService)) {
              description = `Comissió gestió servei ${this.getServiceNameText(
                bookingService
              )}`;
              totalAmount = getBookingServiceComissionPrice(bookingService);
            } else if (
              getBookingServiceComissionIsUnderInvoiced(bookingService) ||
              getBookingServiceComissionIsOverInvoiced(bookingService)
            ) {
              description = getBookingServiceComissionIsUnderInvoiced(
                bookingService
              )
                ? `Comissió restant gestió servei ${this.getServiceNameText(
                    bookingService
                  )}`
                : `Compensació comissió gestió servei ${this.getServiceNameText(
                    bookingService
                  )}`;
              totalAmount =
                getBookingServiceComissionInvoicePendingPrice(bookingService);
            }

            if (description) {
              const baseAmount = calculateBasePriceFromPvpPrice(
                totalAmount,
                AGENCY_TO_CLIENT_INVOICE_VAT_PERCENTAGE
              );
              const vatAmount = calculatePercentagePriceFromBasePrice(
                baseAmount,
                AGENCY_TO_CLIENT_INVOICE_VAT_PERCENTAGE
              );
              const irpfAmount = 0;

              this.billingRows.push({
                description,
                baseAmount,
                vatAmount,
                irpfAmount,
                totalAmount,
                invoiceLineType: "SERVICE_COMISSION",
                service: bookingService["@id"],
              });
            }
          }
        }
      });
    },
    getServiceNameText(bookingService) {
      if (!bookingService?.service) {
        return this.$t("No definit");
      }

      return getServiceName(bookingService.service, this.$i18n.locale);
    },
    onRowsSelected(selectedRows) {
      this.$emit("rows-selected", selectedRows);
    },
  },
};
</script>
