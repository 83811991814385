<template>
  <b-card class="liquidation-details p-2 bg-white">
    <!-- HEADER -->
    <div
      class="d-flex justify-content-between align-items-center flex-md-row flex-column mb-1 mb-md-2"
    >
      <div class="order-1 order-md-0">
        <h2 class="liquidation-details-header-title">LIQUIDACIÓ</h2>
        <div v-if="liquidationDate" class="text-nowrap">
          <b>Data d'emisió:</b> {{ liquidationDate }}
        </div>
      </div>

      <div
        class="liquidation-details-header-logo order-0 order-md-50 mb-1 mb-md-0"
      >
        <logo />
      </div>
    </div>

    <!-- HOLDER -->
    <div class="bg-light p-2 border mb-2">
      <b v-if="holderFullName">
        {{ holderFullName }}
      </b>
      <div v-if="holderIdNumber">
        {{ holderIdNumber }}
      </div>
      <div v-if="holderAddress">
        {{ holderAddress }}
      </div>
      <div v-if="holderPhone">
        {{ holderPhone }}
      </div>
    </div>

    <div class="d-flex justify-content-between flex-md-row flex-column">
      <!-- ACCOMMODATION -->
      <div class="mr-md-3 mb-3 mb-md-0">
        <b class="mb-1"> Allotjament: </b>
        <div v-if="accommodationName" class="m-0">
          {{ accommodationName }}
        </div>
        <div v-if="accommodationTouristLicenceNumber" class="m-0">
          {{ accommodationTouristLicenceNumber }}
        </div>
        <div v-if="accommodationAddress" class="m-0">
          {{ accommodationAddress }}
        </div>
      </div>

      <!-- RECIPIENT -->
      <div>
        <b class="mb-1"> Liquidat a: </b>
        <div v-if="recipientFullName" class="m-0">
          {{ recipientFullName }}
        </div>
        <div v-if="recipientIdNumber" class="m-0">
          {{ recipientIdNumber }}
        </div>
        <div v-if="recipientAddress" class="m-0">
          {{ recipientAddress }}
        </div>
        <div v-if="recipientPhone" class="m-0">
          {{ recipientPhone }}
        </div>
      </div>
    </div>

    <!-- INVOICE LINES -->
    <div v-if="ownerLiquidationLines.length" class="mx-1 mt-4">
      <!-- Header -->
      <b-row
        class="font-weight-bolder text-uppercase bg-light py-1 mb-1 border"
      >
        <b-col cols="4"> Descripció </b-col>
        <b-col cols="2" class="text-right"> Base imponible </b-col>
        <b-col cols="2" class="text-right"> IVA </b-col>
        <b-col cols="2" class="text-right"> IRPF </b-col>
        <b-col cols="2" class="text-right"> Subtotal </b-col>
      </b-row>

      <!-- Invoice lines -->
      <b-row
        v-for="(ownerLiquidationLine, index) in ownerLiquidationLines"
        :key="`invoice-line-${index}`"
        class="py-1"
      >
        <b-col cols="4">
          {{ ownerLiquidationLine.description }}
        </b-col>
        <b-col cols="2" class="text-right">
          {{ formatCurrency(ownerLiquidationLine.basePrice) }}
        </b-col>
        <b-col cols="2" class="text-right">
          {{ formatCurrency(ownerLiquidationLine.vatPrice) }}
        </b-col>
        <b-col cols="2" class="text-right">
          {{ formatCurrency(ownerLiquidationLine.irpfPrice) }}
        </b-col>
        <b-col cols="2" class="text-right">
          {{ formatCurrency(ownerLiquidationLine.pvpPrice) }}
        </b-col>
      </b-row>

      <!-- Footer -->
      <b-row class="text-uppercase mt-1 bg-light border py-1">
        <b-col cols="4" class="font-weight-bolder"> Total </b-col>
        <b-col cols="2" class="text-right">
          {{ formatCurrency(basePriceSum) }}
        </b-col>
        <b-col cols="2" class="text-right">
          {{ formatCurrency(vatPriceSum) }}
        </b-col>
        <b-col cols="2" class="text-right">
          {{ formatCurrency(irpfPriceSum) }}
        </b-col>
        <b-col cols="2" class="font-weight-bolder text-right">
          {{ formatCurrency(pvpPriceSum) }}
        </b-col>
      </b-row>
    </div>

    <!-- NOTES -->
    <div v-if="comments">
      <hr class="my-3" />
      <div>
        {{ comments }}
      </div>
    </div>
  </b-card>
</template>

<script>
import { BRow, BCol, BCard } from "bootstrap-vue";
import Logo from "@/assets/foravila/images/logos/ForavilaRentalsLogo.vue";
import { formatDateStringToDate, formatCurrency } from "@/utils/formatters";

export default {
  components: {
    BRow,
    BCol,
    Logo,
    BCard,
  },
  props: {
    liquidation: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      formatCurrency,
    };
  },
  computed: {
    liquidationDate() {
      return formatDateStringToDate(this.liquidation?.emittedAt) || null;
    },
    holderFirstName() {
      return this.liquidation?.holderFirstName || null;
    },
    holderLastName() {
      return this.liquidation?.holderLastName || null;
    },
    holderFullName() {
      const name = [];
      if (this.holderFirstName) name.push(this.holderFirstName);
      if (this.holderLastName) name.push(this.holderLastName);
      return name.length ? name.join(" ") : null;
    },
    holderIdNumber() {
      return this.liquidation?.holderIdNumber || null;
    },
    holderAddress() {
      return this.liquidation?.holderAddress || null;
    },
    holderPhone() {
      return this.liquidation?.holderPhone || null;
    },
    recipientFirstName() {
      return this.liquidation?.recipientFirstName || null;
    },
    recipientLastName() {
      return this.liquidation?.recipientLastName || null;
    },
    recipientFullName() {
      const name = [];
      if (this.recipientFirstName) name.push(this.recipientFirstName);
      if (this.recipientLastName) name.push(this.recipientLastName);
      return name.length ? name.join(" ") : null;
    },
    recipientIdNumber() {
      return this.liquidation?.recipientIdNumber || null;
    },
    recipientAddress() {
      return this.liquidation?.recipientAddress || null;
    },
    recipientPhone() {
      return this.liquidation?.recipientPhone || null;
    },
    accommodationName() {
      return this.liquidation?.accommodationName || null;
    },
    accommodationAddress() {
      return this.liquidation?.accommodationAddress || null;
    },
    accommodationTouristLicenceNumber() {
      return this.liquidation?.accommodationTouristLicenceNumber || null;
    },
    ownerLiquidationLines() {
      return this.liquidation?.ownerLiquidationLines || [];
    },
    basePriceSum() {
      return (
        this.ownerLiquidationLines.reduce(
          (sum, ownerLiquidationLine) => sum + ownerLiquidationLine.basePrice,
          0
        ) || 0
      );
    },
    vatPriceSum() {
      return (
        this.ownerLiquidationLines.reduce(
          (sum, ownerLiquidationLine) => sum + ownerLiquidationLine.vatPrice,
          0
        ) || 0
      );
    },
    irpfPriceSum() {
      return (
        this.ownerLiquidationLines.reduce(
          (sum, ownerLiquidationLine) => sum + ownerLiquidationLine.irpfPrice,
          0
        ) || 0
      );
    },
    pvpPriceSum() {
      return (
        this.ownerLiquidationLines.reduce(
          (sum, ownerLiquidationLine) => sum + ownerLiquidationLine.pvpPrice,
          0
        ) || 0
      );
    },
    comments() {
      return this.liquidation?.comments || null;
    },
  },
};
</script>

<style lang="scss">
.liquidation-details {
  font-size: 12px;

  .liquidation-details-header-title {
    font-size: 16px;
  }

  .liquidation-details-header-logo {
    width: 10rem;
    svg {
      width: 10rem;
    }
  }
}
</style>
