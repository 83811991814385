<template>
  <div>
    <template v-if="!contract && !loadingBooking">
      <b-card>
        <b-alert class="mb-0" variant="danger" :show="true">
          <div class="alert-body">
            Aquesta reserva no disposa d'un contracte associat i no es pot
            facturar
          </div>
        </b-alert>
      </b-card>
    </template>

    <template v-if="contract">
      <!-- SUMMARY -->
      <b-card title="Detalls">
        <billing-summary />
      </b-card>

      <b-row>
        <!-- INVOICES LIST -->
        <b-col cols="12" md="6">
          <b-card title="Factures emeses">
            <b-overlay :show="loadingInvoices" variant="white" opacity="1">
              <invoices-list :class="{ 'pb-3': loadingInvoices }" />
            </b-overlay>
          </b-card>
        </b-col>

        <!-- LIQUIDATIONS LIST -->
        <b-col cols="12" md="6">
          <b-card title="Liquidacions emeses">
            <b-overlay
              :show="loadingOwnerLiquidations"
              variant="white"
              opacity="1"
            >
              <liquidations-list
                :class="{ 'pb-3': loadingOwnerLiquidations }"
              />
            </b-overlay>
          </b-card>
        </b-col>
      </b-row>

      <!-- PENDING BILLING -->
      <b-card title="Facturació pendent">
        <b-overlay :show="loadingBillingData" variant="white" opacity="1">
          <agency-to-owner-pending
            @rows-selected="onAgencyToOwnerPendingRowsSelected"
          />
          <agency-to-client-pending
            @rows-selected="onAgencyToClientPendingRowsSelected"
          />
          <owner-settlement-pending
            @rows-selected="onOwnerSettlementPendingRowsSelected"
          />
          <div class="d-flex justify-content-end mt-2">
            <b-button
              :disabled="!isPreviewButtonActive"
              variant="primary"
              @click="onButtonClicked"
            >
              Previsualitza
            </b-button>
          </div>
        </b-overlay>
      </b-card>
    </template>
  </div>
</template>

<script>
import { BOverlay, BCard, BButton, BAlert, BRow, BCol } from "bootstrap-vue";
import AgencyToOwnerPending from "@/views/accounting/billing/details/components/AgencyToOwnerPending.vue";
import AgencyToClientPending from "@/views/accounting/billing/details/components/AgencyToClientPending.vue";
import OwnerSettlementPending from "@/views/accounting/billing/details/components/OwnerSettlementPending.vue";
import BillingSummary from "@/views/accounting/billing/details/components/BillingSummary.vue";
import InvoicesList from "@/views/accounting/billing/details/components/InvoicesList.vue";
import LiquidationsList from "@/views/accounting/billing/details/components/LiquidationsList.vue";
import { notifyError } from "@/utils/methods";

export default {
  components: {
    AgencyToClientPending,
    AgencyToOwnerPending,
    OwnerSettlementPending,
    BillingSummary,
    InvoicesList,
    LiquidationsList,
    BOverlay,
    BCard,
    BButton,
    BAlert,
    BRow,
    BCol,
  },
  data() {
    return {
      agencyToClientSelectedPendingInvoiceLines: [],
      agencyToOwnerSelectedPendingInvoiceLines: [],
      ownerSettlementSelectedPendingInvoiceLines: [],
    };
  },
  computed: {
    loadingBooking() {
      return this.$store.getters["booking/loading"];
    },
    booking() {
      return this.$store.getters["booking/booking"];
    },
    contract() {
      return this.$store.getters["booking/contract"];
    },
    accommodation() {
      return this.$store.getters["accommodation/accommodation"];
    },
    isPreviewButtonActive() {
      if (
        this.agencyToClientSelectedPendingInvoiceLines.length ||
        this.agencyToOwnerSelectedPendingInvoiceLines.length ||
        this.ownerSettlementSelectedPendingInvoiceLines.length
      ) {
        return true;
      }

      return false;
    },
    loadingInvoices() {
      return this.$store.getters["booking/loadingInvoices"];
    },
    loadingOwnerLiquidations() {
      return this.$store.getters["booking/loadingOwnerLiquidations"];
    },
    loadingServices() {
      return this.$store.getters["booking/loadingServices"];
    },
    loadingRates() {
      return this.$store.getters["booking/loadingRates"];
    },
    loadingBillingData() {
      return (
        this.loadingInvoices ||
        this.loadingOwnerLiquidations ||
        this.loadingServices ||
        this.loadingRates
      );
    },
  },
  created() {
    this.fetchBillingData();
  },
  methods: {
    async fetchBillingData() {
      this.$store.dispatch("app/setLoading", true);

      const { localizator } = this.$route.params;

      // Wait until booking data is fetched
      await this.$store
        .dispatch("booking/fetchBookingByLocalizator", localizator)
        .catch(() => {
          notifyError(
            this.$t("errors.fetchBooking.title"),
            this.$t("errors.fetchBooking.description")
          );
        });

      this.$store.dispatch("app/setLoading", false);

      // If the booking has not been found, return to the pending billing page
      if (!this.booking) {
        this.$router.push("foravila-accounting-billing-pending");
        return;
      }

      // Wait for the main billing data to be fetched
      const { uuid, client, accommodation } = this.booking;
      const billingDataRequests = [
        this.$store.dispatch("booking/fetchInvoices", uuid).catch(() => {
          notifyError(
            this.$t("errors.fetchInvoices.title"),
            this.$t("errors.fetchInvoices.description")
          );
        }),
        this.$store
          .dispatch("booking/fetchOwnerLiquidations", uuid)
          .catch(() => {
            notifyError(
              this.$t("errors.fetchOwnerLiquidations.title"),
              this.$t("errors.fetchOwnerLiquidations.description")
            );
          }),
        this.$store.dispatch("booking/fetchServices", uuid).catch(() => {
          notifyError(
            this.$t("errors.fetchServices.title"),
            this.$t("errors.fetchServices.description")
          );
        }),
        this.$store.dispatch("booking/fetchRates", uuid).catch(() => {
          notifyError(
            this.$t("errors.fetchRates.title"),
            this.$t("errors.fetchRates.description")
          );
        }),
      ];
      await Promise.all(billingDataRequests);

      // Fetch the client data
      this.$store.dispatch("booking/fetchClient", client?.uuid).catch(() => {
        notifyError(
          this.$t("errors.fetchClient.title"),
          this.$t("errors.fetchClient.description")
        );
      });

      // Fetch the accommodation data
      await this.$store
        .dispatch("accommodation/fetchAccommodation", accommodation?.uuid)
        .catch(() => {
          notifyError(
            this.$t("errors.fetchAccommodation.title"),
            this.$t("errors.fetchAccommodation.description")
          );
        });

      // Fetch the owner and contract data
      const { owner, contract } = this.accommodation || {};

      if (owner) {
        this.$store
          .dispatch("accommodation/fetchOwner", owner.uuid)
          .catch(() => {
            notifyError(
              this.$t("errors.fetchOwner.title"),
              this.$t("errors.fetchOwner.description")
            );
          });
      }

      if (contract) {
        this.$store
          .dispatch("accommodation/fetchContract", contract.uuid)
          .catch(() => {
            notifyError(
              this.$t("errors.fetchContract.title"),
              this.$t("errors.fetchContract.description")
            );
          });
      }
    },
    onButtonClicked() {
      const promises = [
        this.$store.dispatch(
          "billingProcess/saveAgencyToClientSelectedPendingInvoiceLines",
          this.agencyToClientSelectedPendingInvoiceLines
        ),
        this.$store.dispatch(
          "billingProcess/saveAgencyToOwnerSelectedPendingInvoiceLines",
          this.agencyToOwnerSelectedPendingInvoiceLines
        ),
        this.$store.dispatch(
          "billingProcess/saveOwnerSettlementSelectedPendingInvoiceLines",
          this.ownerSettlementSelectedPendingInvoiceLines
        ),
      ];

      Promise.all(promises)
        .then(() =>
          this.$router.push({
            name: "foravila-accounting-billing-preview",
          })
        )
        .catch((error) => {
          console.error(error);
        });
    },
    onAgencyToClientPendingRowsSelected(selectedRows) {
      this.agencyToClientSelectedPendingInvoiceLines = selectedRows;
    },
    onAgencyToOwnerPendingRowsSelected(selectedRows) {
      this.agencyToOwnerSelectedPendingInvoiceLines = selectedRows;
    },
    onOwnerSettlementPendingRowsSelected(selectedRows) {
      this.ownerSettlementSelectedPendingInvoiceLines = selectedRows;
    },
  },
};
</script>
