<template>
  <pending-billing-table
    v-if="billingRows.length"
    :rows="billingRows"
    invoice-type="AGENCY_TO_OWNER"
    @rows-selected="onRowsSelected"
  />
</template>

<script>
import { AGENCY_TO_OWNER_INVOICE_VAT_PERCENTAGE } from "@appConfig";
import PendingBillingTable from "@/views/accounting/billing/details/components/PendingBillingTable.vue";
import {
  calculateBasePriceFromPvpPrice,
  calculatePercentagePriceFromBasePrice,
} from "@/utils/methods";

export default {
  components: {
    PendingBillingTable,
  },
  data() {
    return {
      billingRows: [],
    };
  },
  created() {
    this.initBillingRows();
  },
  computed: {
    contract() {
      return this.$store.getters["booking/contract"];
    },
    billingType() {
      return this.contract?.billingType || null;
    },
    booking() {
      return this.$store.getters["booking/booking"];
    },
    bookingLocalizator() {
      return this.booking?.localizator || null;
    },
    bookingAgencyPrice() {
      return this.$store.getters["booking/agencySubtotal"];
    },
    bookingAgencyPriceDescription() {
      const description = [this.$t("Comissió agència")];

      if (this.bookingLocalizator) {
        description.push(`Reserva ${this.bookingLocalizator}`);
      }

      return description.join(" · ");
    },
    isStayComissionInvoiced() {
      return this.$store.getters["booking/isStayComissionInvoiced"];
    },
    isStayComissionUnderInvoiced() {
      return this.$store.getters["booking/isStayComissionUnderInvoiced"];
    },
    isStayComissionOverInvoiced() {
      return this.$store.getters["booking/isStayComissionOverInvoiced"];
    },
    stayComissionInvoicePendingPrice() {
      return this.$store.getters["booking/stayComissionInvoicePendingPrice"];
    },
  },
  watch: {
    isStayComissionInvoiced() {
      this.initBillingRows();
    },
  },
  methods: {
    initBillingRows() {
      this.billingRows = [];

      if (this.billingType !== "COMISSION") {
        return;
      }

      let totalAmount;
      let description;

      // STAY COMISSION INVOICE LINE
      if (!this.isStayComissionInvoiced) {
        description = this.bookingAgencyPriceDescription;
        totalAmount = this.bookingAgencyPrice;
      } else if (
        this.isStayComissionUnderInvoiced ||
        this.isStayComissionOverInvoiced
      ) {
        description = this.isStayComissionUnderInvoiced
          ? `Estada restant · Reserva ${this.booking.localizator}`
          : `Compensació estada · Reserva ${this.booking.localizator}`;
        totalAmount = this.stayComissionInvoicePendingPrice;
      }

      if (description) {
        const baseAmount = calculateBasePriceFromPvpPrice(
          totalAmount,
          AGENCY_TO_OWNER_INVOICE_VAT_PERCENTAGE
        );
        const vatAmount = calculatePercentagePriceFromBasePrice(
          baseAmount,
          AGENCY_TO_OWNER_INVOICE_VAT_PERCENTAGE
        );
        const irpfAmount = 0;

        this.billingRows.push({
          description,
          totalAmount,
          baseAmount,
          vatAmount,
          irpfAmount,
          invoiceLineType: "STAY_COMISSION",
        });
      }
    },
    onRowsSelected(selectedRows) {
      this.$emit("rows-selected", selectedRows);
    },
  },
};
</script>
