<template>
  <div>
    <b-row>
      <b-col cols="12" v-if="!invoices.length">
        <b-alert class="mb-0" variant="danger" :show="true">
          <div class="alert-body">
            Encara no s'ha emès cap factura per aquesta reserva
          </div>
        </b-alert>
      </b-col>
      <b-col cols="6" md="3" v-for="invoice in invoices" :key="invoice.uuid">
        <invoice-card
          :invoice="invoice"
          @preview="openInvoicePreviewModal"
          @send="openSendInvoiceModal"
          @download="downloadInvoice"
        />
      </b-col>
    </b-row>

    <b-modal
      id="invoice-preview-modal"
      :title="invoicePreviewTitle"
      hide-footer
      scrollable
      size="lg"
      lazy
    >
      <invoice-details v-if="selectedInvoice" :invoice="selectedInvoice" />
    </b-modal>

    <b-modal id="send-invoice-modal">
      <invoice-send :invoice="selectedInvoice" />
    </b-modal>
  </div>
</template>

<script>
import { BRow, BCol, BModal, BAlert } from "bootstrap-vue";
import InvoiceSend from "@/views/accounting/billing/details/components/InvoiceSend.vue";
import InvoiceCard from "@/views/accounting/billing/details/components/InvoiceCard.vue";
import InvoiceDetails from "@/views/accounting/billing/details/components/InvoiceDetails.vue";

export default {
  components: {
    BRow,
    BCol,
    BModal,
    BAlert,
    InvoiceSend,
    InvoiceCard,
    InvoiceDetails,
  },
  data() {
    return {
      selectedInvoice: null,
      invoicePreviewTitle: null,
    };
  },
  computed: {
    invoices() {
      return this.$store.getters["booking/invoices"];
    },
  },
  methods: {
    openInvoicePreviewModal(invoice) {
      this.selectedInvoice = invoice;
      this.invoicePreviewTitle = `Previsualització de la factura ${invoice.localizator}`;
      this.$bvModal.show("invoice-preview-modal");
    },
    openSendInvoiceModal(invoice) {
      // TODO
      this.selectedInvoice = invoice;
      this.$bvModal.show("send-invoice-modal");
    },
    downloadInvoice() {
      // TODO
    },
  },
};
</script>
