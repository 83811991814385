<template>
  <div>
    <b-row>
      <b-col cols="12" v-if="!liquidations.length">
        <b-alert class="mb-0" variant="danger" :show="true">
          <div class="alert-body">
            Encara no s'ha emès cap liquidació per aquesta reserva
          </div>
        </b-alert>
      </b-col>
      <b-col
        cols="6"
        md="3"
        v-for="liquidation in liquidations"
        :key="liquidation.uuid"
      >
        <liquidation-card
          :liquidation="liquidation"
          @preview="openLiquidationPreviewModal"
          @send="openSendLiquidationModal"
          @download="downloadLiquidation"
        />
      </b-col>
    </b-row>

    <b-modal
      id="liquidation-preview-modal"
      hide-footer
      size="lg"
      :title="liquidationPreviewTitle"
    >
      <liquidation-details
        v-if="selectedLiquidation"
        :liquidation="selectedLiquidation"
      />
    </b-modal>

    <b-modal id="send-liquidation-modal">
      <liquidation-send :liquidation="selectedLiquidation" />
    </b-modal>
  </div>
</template>

<script>
import { BRow, BCol, BModal, BAlert } from "bootstrap-vue";
import LiquidationCard from "@/views/accounting/billing/details/components/LiquidationCard.vue";
import LiquidationDetails from "@/views/accounting/billing/details/components/LiquidationDetails.vue";

export default {
  components: {
    BRow,
    BCol,
    BModal,
    BAlert,
    LiquidationCard,
    LiquidationDetails,
  },
  data() {
    return {
      selectedLiquidation: null,
      liquidationPreviewTitle: null,
    };
  },
  computed: {
    liquidations() {
      return this.$store.getters["booking/ownerLiquidations"];
    },
  },
  methods: {
    openLiquidationPreviewModal(liquidation) {
      this.selectedLiquidation = liquidation;
      this.liquidationPreviewTitle = "Liquidació";
      this.$bvModal.show("liquidation-preview-modal");
    },
    openSendLiquidationModal() {
      // TODO
    },
    downloadLiquidation() {
      // TODO
    },
  },
};
</script>
