<template>
  <div>
    <pending-billing-table
      v-if="settlementRows.length"
      :rows="settlementRows"
      invoice-type="OWNER_SETTLEMENT"
      @rows-selected="onRowsSelected"
    />
  </div>
</template>

<script>
import PendingBillingTable from "@/views/accounting/billing/details/components/PendingBillingTable.vue";
import {
  getServiceName,
  calculatePercentagePriceFromBasePrice,
  calculatePvpPrice,
} from "@/utils/methods";
import { formatDateStringToDate } from "@/utils/formatters";

export default {
  components: {
    PendingBillingTable,
  },
  computed: {
    contract() {
      return this.$store.getters["booking/contract"];
    },
    billingType() {
      return this.contract?.billingType || null;
    },
    booking() {
      return this.$store.getters["booking/booking"];
    },
    services() {
      return this.$store.getters["booking/services"];
    },
    bookingPvpPrice() {
      return this.$store.getters["booking/subtotalDiscounted"];
    },
    bookingAgencyPrice() {
      return this.$store.getters["booking/agencySubtotal"];
    },
    bookingOwnerPrice() {
      return this.$store.getters["booking/ownerSubtotal"];
    },
    bookingSettlementRowDescription() {
      const text = [`${this.$t("Reserva")} ${this.booking.localizator}`];

      const { checkin, checkout, client } = this.booking || {};

      if (checkin && checkout) {
        text.push(
          `${formatDateStringToDate(
            checkin,
            this.$i18n.locale
          )} ➡ ${formatDateStringToDate(checkout, this.$i18n.locale)}`
        );
      }

      if (client) {
        const { fullName, idNumber } = client || {};
        text.push(`${fullName}${idNumber ? ", " + idNumber : ""}`);
      }

      return text.length ? text.join(" · ") : null;
    },
    settlementRows() {
      const data = [];
      const { ownerVatPercentage, ownerIrpfPercentage } = this.contract || {};

      // BOOKING AMOUNT SETTLEMENT
      const baseAmount = this.bookingOwnerPrice;
      const vatAmount = calculatePercentagePriceFromBasePrice(
        baseAmount,
        this.billingType === "COMISSION" ? 0 : ownerVatPercentage || 0
      );
      const irpfAmount = calculatePercentagePriceFromBasePrice(
        baseAmount,
        this.billingType === "COMISSION" ? 0 : ownerIrpfPercentage || 0
      );
      const totalAmount = calculatePvpPrice(baseAmount, vatAmount, irpfAmount);

      data.push({
        description: this.bookingSettlementRowDescription,
        baseAmount,
        vatAmount,
        irpfAmount,
        totalAmount,
      });

      // EARLY RETURN IN CASE THERE ARE NO BOOKING SERVICES
      if (!this.services.length) {
        return data;
      }

      // SERVICES SETTLEMENT
      this.services.forEach((bookingService) => {
        const { service, costPrice, vatPercentage, irpfPercentage } =
          bookingService || {};

        if (service?.code !== "SECURITY_DEPOSIT" && costPrice > 0) {
          const serviceBaseAmount = costPrice;
          const serviceVatAmount = calculatePercentagePriceFromBasePrice(
            serviceBaseAmount,
            vatPercentage ?? (ownerVatPercentage || 0)
          );
          const serviceIrpfAmount = calculatePercentagePriceFromBasePrice(
            serviceBaseAmount,
            irpfPercentage ?? (ownerIrpfPercentage || 0)
          );
          const serviceTotalAmount = calculatePvpPrice(
            serviceBaseAmount,
            serviceVatAmount,
            serviceIrpfAmount
          );

          data.push({
            description: this.getServiceNameText(bookingService),
            baseAmount: serviceBaseAmount,
            vatAmount: serviceVatAmount,
            irpfAmount: serviceIrpfAmount,
            totalAmount: serviceTotalAmount,
          });
        }
      });

      return data;
    },
  },

  methods: {
    getServiceNameText(bookingService) {
      if (!bookingService?.service) {
        return this.$t("No definit");
      }

      return getServiceName(bookingService.service, this.$i18n.locale);
    },
    onRowsSelected(selectedRows) {
      this.$emit("rows-selected", selectedRows);
    },
  },
};
</script>
